
var ortWasm = (() => {
  var _scriptDir = typeof document !== 'undefined' && document.currentScript ? document.currentScript.src : undefined;
  if (typeof __filename !== 'undefined') _scriptDir = _scriptDir || __filename;
  return (
function(moduleArg = {}) {

var d=moduleArg,aa,k;d.ready=new Promise((a,b)=>{aa=a;k=b});"use strict";d.mountExternalData=(a,b)=>{(d.Fa||(d.Fa=new Map)).set(a,b)};d.unmountExternalData=()=>{delete d.Fa};
let ca=()=>{const a=(c,e,f)=>(...h)=>{const l=n,m=e?.();h=c(...h);const q=e?.();m!==q&&(c=q,f(m),e=f=null);return n!=l?ba():h},b=c=>async(...e)=>{try{if(d.Ea)throw Error("Session already started");const f=d.Ea={Za:e[0],errors:[]},h=await c(...e);if(d.Ea!==f)throw Error("Session mismatch");d.La?.flush();const l=f.errors;if(0<l.length){let m=await Promise.all(l);m=m.filter(q=>q);if(0<m.length)throw Error(m.join("\n"));}return h}finally{d.Ea=null}};d._OrtCreateSession=a(d._OrtCreateSession,()=>d._OrtCreateSession,
c=>d._OrtCreateSession=c);d._OrtRun=b(a(d._OrtRun,()=>d._OrtRun,c=>d._OrtRun=c));d._OrtRunWithBinding=b(a(d._OrtRunWithBinding,()=>d._OrtRunWithBinding,c=>d._OrtRunWithBinding=c));d._OrtBindInput=a(d._OrtBindInput,()=>d._OrtBindInput,c=>d._OrtBindInput=c);ca=void 0};
d.jsepInit=(a,b)=>{ca?.();if("webgpu"===a){[d.La,d.Ra,d.Va,d.Ma,d.Ua,d.sa,d.Wa,d.Ya,d.Sa,d.Ta,d.Xa]=b;const c=d.La;d.jsepRegisterBuffer=(e,f,h,l)=>c.registerBuffer(e,f,h,l);d.jsepGetBuffer=e=>c.getBuffer(e);d.jsepCreateDownloader=(e,f,h)=>c.createDownloader(e,f,h);d.jsepOnReleaseSession=e=>{c.onReleaseSession(e)};d.jsepOnRunStart=e=>c.onRunStart(e)}};
var da=Object.assign({},d),ea="./this.program",x=(a,b)=>{throw b;},fa="object"==typeof window,y="function"==typeof importScripts,ha="object"==typeof process&&"object"==typeof process.versions&&"string"==typeof process.versions.node,z="",A,B,C;
if(ha){var fs=require("fs"),D=require("path");z=y?D.dirname(z)+"/":__dirname+"/";A=(a,b)=>{a=E(a)?new URL(a):D.normalize(a);return fs.readFileSync(a,b?void 0:"utf8")};C=a=>{a=A(a,!0);a.buffer||(a=new Uint8Array(a));return a};B=(a,b,c,e=!0)=>{a=E(a)?new URL(a):D.normalize(a);fs.readFile(a,e?void 0:"utf8",(f,h)=>{f?c(f):b(e?h.buffer:h)})};!d.thisProgram&&1<process.argv.length&&(ea=process.argv[1].replace(/\\/g,"/"));process.argv.slice(2);x=(a,b)=>{process.exitCode=a;throw b;};d.inspect=()=>"[Emscripten Module object]"}else if(fa||
y)y?z=self.location.href:"undefined"!=typeof document&&document.currentScript&&(z=document.currentScript.src),_scriptDir&&(z=_scriptDir),0!==z.indexOf("blob:")?z=z.substr(0,z.replace(/[?#].*/,"").lastIndexOf("/")+1):z="",A=a=>{var b=new XMLHttpRequest;b.open("GET",a,!1);b.send(null);return b.responseText},y&&(C=a=>{var b=new XMLHttpRequest;b.open("GET",a,!1);b.responseType="arraybuffer";b.send(null);return new Uint8Array(b.response)}),B=(a,b,c)=>{var e=new XMLHttpRequest;e.open("GET",a,!0);e.responseType=
"arraybuffer";e.onload=()=>{200==e.status||0==e.status&&e.response?b(e.response):c()};e.onerror=c;e.send(null)};var ia=console.log.bind(console),G=console.error.bind(console);Object.assign(d,da);da=null;"object"!=typeof WebAssembly&&H("no native wasm support detected");var I,J=!1,K,L,M,N,O,ja,ka;
function la(){var a=I.buffer;d.HEAP8=L=new Int8Array(a);d.HEAP16=new Int16Array(a);d.HEAPU8=M=new Uint8Array(a);d.HEAPU16=new Uint16Array(a);d.HEAP32=N=new Int32Array(a);d.HEAPU32=O=new Uint32Array(a);d.HEAPF32=ja=new Float32Array(a);d.HEAPF64=ka=new Float64Array(a)}var ma=[],na=[],oa=[],P=0,pa=null,Q=null;function H(a){a="Aborted("+a+")";G(a);J=!0;K=1;a=new WebAssembly.RuntimeError(a+". Build with -sASSERTIONS for more info.");k(a);throw a;}
var qa=a=>a.startsWith("data:application/octet-stream;base64,"),E=a=>a.startsWith("file://"),R;R="ort-wasm-simd.wasm";if(!qa(R)){var ra=R;R=d.locateFile?d.locateFile(ra,z):z+ra}function sa(a){if(C)return C(a);throw"both async and sync fetching of the wasm failed";}
function ta(a){if(fa||y){if("function"==typeof fetch&&!E(a))return fetch(a,{credentials:"same-origin"}).then(b=>{if(!b.ok)throw"failed to load wasm binary file at '"+a+"'";return b.arrayBuffer()}).catch(()=>sa(a));if(B)return new Promise((b,c)=>{B(a,e=>b(new Uint8Array(e)),c)})}return Promise.resolve().then(()=>sa(a))}function ua(a,b,c){return ta(a).then(e=>WebAssembly.instantiate(e,b)).then(e=>e).then(c,e=>{G(`failed to asynchronously prepare wasm: ${e}`);H(e)})}
function va(a,b){var c=R;return"function"!=typeof WebAssembly.instantiateStreaming||qa(c)||E(c)||ha||"function"!=typeof fetch?ua(c,a,b):fetch(c,{credentials:"same-origin"}).then(e=>WebAssembly.instantiateStreaming(e,a).then(b,function(f){G(`wasm streaming compile failed: ${f}`);G("falling back to ArrayBuffer instantiation");return ua(c,a,b)}))}
var S,wa={931760:(a,b,c,e)=>{if("undefined"==typeof d||!d.Fa)return 1;a=T(a>>>0);a.startsWith("./")&&(a=a.substring(2));a=d.Fa.get(a);if(!a)return 2;b>>>=0;c>>>=0;if(b+c>a.byteLength)return 3;try{return M.set(a.subarray(b,b+c),e>>>0>>>0),0}catch{return 4}},932261:()=>{d.Sa()},932292:()=>{d.Ta()},932321:()=>{d.Xa()},932346:a=>d.Ra(a),932379:a=>d.Va(a),932411:(a,b,c)=>{d.Ma(a,b,c,!0)},932450:(a,b,c)=>{d.Ma(a,b,c)},932483:a=>{d.sa("Abs",a,void 0)},932534:a=>{d.sa("Neg",a,void 0)},932585:a=>{d.sa("Floor",
a,void 0)},932638:a=>{d.sa("Ceil",a,void 0)},932690:a=>{d.sa("Reciprocal",a,void 0)},932748:a=>{d.sa("Sqrt",a,void 0)},932800:a=>{d.sa("Exp",a,void 0)},932851:a=>{d.sa("Erf",a,void 0)},932902:a=>{d.sa("Sigmoid",a,void 0)},932957:(a,b,c)=>{d.sa("HardSigmoid",a,{alpha:b,beta:c})},933036:a=>{d.sa("Log",a,void 0)},933087:a=>{d.sa("Sin",a,void 0)},933138:a=>{d.sa("Cos",a,void 0)},933189:a=>{d.sa("Tan",a,void 0)},933240:a=>{d.sa("Asin",a,void 0)},933292:a=>{d.sa("Acos",a,void 0)},933344:a=>{d.sa("Atan",
a,void 0)},933396:a=>{d.sa("Sinh",a,void 0)},933448:a=>{d.sa("Cosh",a,void 0)},933500:a=>{d.sa("Asinh",a,void 0)},933553:a=>{d.sa("Acosh",a,void 0)},933606:a=>{d.sa("Atanh",a,void 0)},933659:a=>{d.sa("Tanh",a,void 0)},933711:a=>{d.sa("Not",a,void 0)},933762:(a,b,c)=>{d.sa("Clip",a,{min:b,max:c})},933831:a=>{d.sa("Clip",a,void 0)},933883:(a,b)=>{d.sa("Elu",a,{alpha:b})},933941:a=>{d.sa("Relu",a,void 0)},933993:(a,b)=>{d.sa("LeakyRelu",a,{alpha:b})},934057:(a,b)=>{d.sa("ThresholdedRelu",a,{alpha:b})},
934127:(a,b)=>{d.sa("Cast",a,{to:b})},934185:a=>{d.sa("Add",a,void 0)},934236:a=>{d.sa("Sub",a,void 0)},934287:a=>{d.sa("Mul",a,void 0)},934338:a=>{d.sa("Div",a,void 0)},934389:a=>{d.sa("Pow",a,void 0)},934440:a=>{d.sa("Equal",a,void 0)},934493:a=>{d.sa("Greater",a,void 0)},934548:a=>{d.sa("GreaterOrEqual",a,void 0)},934610:a=>{d.sa("Less",a,void 0)},934662:a=>{d.sa("LessOrEqual",a,void 0)},934721:(a,b,c,e,f)=>{d.sa("ReduceMean",a,{keepDims:!!b,noopWithEmptyAxes:!!c,axes:e?Array.from(N.subarray(e>>>
0,f>>>0)):[]})},934880:(a,b,c,e,f)=>{d.sa("ReduceMax",a,{keepDims:!!b,noopWithEmptyAxes:!!c,axes:e?Array.from(N.subarray(e>>>0,f>>>0)):[]})},935038:(a,b,c,e,f)=>{d.sa("ReduceMin",a,{keepDims:!!b,noopWithEmptyAxes:!!c,axes:e?Array.from(N.subarray(e>>>0,f>>>0)):[]})},935196:(a,b,c,e,f)=>{d.sa("ReduceProd",a,{keepDims:!!b,noopWithEmptyAxes:!!c,axes:e?Array.from(N.subarray(e>>>0,f>>>0)):[]})},935355:(a,b,c,e,f)=>{d.sa("ReduceSum",a,{keepDims:!!b,noopWithEmptyAxes:!!c,axes:e?Array.from(N.subarray(e>>>
0,f>>>0)):[]})},935513:(a,b,c,e,f)=>{d.sa("ReduceL1",a,{keepDims:!!b,noopWithEmptyAxes:!!c,axes:e?Array.from(N.subarray(e>>>0,f>>>0)):[]})},935670:(a,b,c,e,f)=>{d.sa("ReduceL2",a,{keepDims:!!b,noopWithEmptyAxes:!!c,axes:e?Array.from(N.subarray(e>>>0,f>>>0)):[]})},935827:(a,b,c,e,f)=>{d.sa("ReduceLogSum",a,{keepDims:!!b,noopWithEmptyAxes:!!c,axes:e?Array.from(N.subarray(e>>>0,f>>>0)):[]})},935988:(a,b,c,e,f)=>{d.sa("ReduceSumSquare",a,{keepDims:!!b,noopWithEmptyAxes:!!c,axes:e?Array.from(N.subarray(e>>>
0,f>>>0)):[]})},936152:(a,b,c,e,f)=>{d.sa("ReduceLogSumExp",a,{keepDims:!!b,noopWithEmptyAxes:!!c,axes:e?Array.from(N.subarray(e>>>0,f>>>0)):[]})},936316:a=>{d.sa("Where",a,void 0)},936369:(a,b,c)=>{d.sa("Transpose",a,{perm:b?Array.from(N.subarray(b>>>0,c>>>0)):[]})},936477:(a,b,c,e,f,h,l,m,q,r,t,v,w,g,p)=>{d.sa("ConvTranspose",a,{format:q?"NHWC":"NCHW",autoPad:b,dilations:[c],group:e,kernel_shape:[f],pads:[h,l],strides:[m],wIsConst:()=>!!L[r>>>0],outputPadding:t?Array.from(N.subarray(t>>>0,v>>>0)):
[],outputShape:w?Array.from(N.subarray(w>>>0,g>>>0)):[],activation:T(p)})},936879:(a,b,c,e,f,h,l,m,q,r,t,v,w,g)=>{d.sa("ConvTranspose",a,{format:m?"NHWC":"NCHW",autoPad:b,dilations:Array.from(N.subarray(c>>>0,(c>>>0)+2>>>0)),group:e,kernelShape:Array.from(N.subarray(f>>>0,(f>>>0)+2>>>0)),pads:Array.from(N.subarray(h>>>0,(h>>>0)+4>>>0)),strides:Array.from(N.subarray(l>>>0,(l>>>0)+2>>>0)),wIsConst:()=>!!L[q>>>0],outputPadding:r?Array.from(N.subarray(r>>>0,t>>>0)):[],outputShape:v?Array.from(N.subarray(v>>>
0,w>>>0)):[],activation:T(g)})},937444:(a,b,c,e,f,h,l,m,q,r,t,v,w,g,p)=>{d.sa("ConvTranspose",a,{format:q?"NHWC":"NCHW",autoPad:b,dilations:[c],group:e,kernel_shape:[f],pads:[h,l],strides:[m],wIsConst:()=>!!L[r>>>0],outputPadding:t?Array.from(N.subarray(t>>>0,v>>>0)):[],outputShape:w?Array.from(N.subarray(w>>>0,g>>>0)):[],activation:T(p)})},937846:(a,b,c,e,f,h,l,m,q,r,t,v,w,g)=>{d.sa("ConvTranspose",a,{format:m?"NHWC":"NCHW",autoPad:b,dilations:Array.from(N.subarray(c>>>0,(c>>>0)+2>>>0)),group:e,
kernelShape:Array.from(N.subarray(f>>>0,(f>>>0)+2>>>0)),pads:Array.from(N.subarray(h>>>0,(h>>>0)+4>>>0)),strides:Array.from(N.subarray(l>>>0,(l>>>0)+2>>>0)),wIsConst:()=>!!L[q>>>0],outputPadding:r?Array.from(N.subarray(r>>>0,t>>>0)):[],outputShape:v?Array.from(N.subarray(v>>>0,w>>>0)):[],activation:T(g)})},938411:(a,b)=>{d.sa("GlobalAveragePool",a,{format:b?"NHWC":"NCHW"})},938502:(a,b,c,e,f,h,l,m,q,r,t,v,w,g,p,u)=>{d.sa("AveragePool",a,{format:u?"NHWC":"NCHW",auto_pad:b,ceil_mode:c,count_include_pad:e,
storage_order:f,dilations:[h,l],kernel_shape:[m,q],pads:[r,t,v,w],strides:[g,p]})},938786:(a,b)=>{d.sa("GlobalAveragePool",a,{format:b?"NHWC":"NCHW"})},938877:(a,b,c,e,f,h,l,m,q,r,t,v,w,g,p,u)=>{d.sa("AveragePool",a,{format:u?"NHWC":"NCHW",auto_pad:b,ceil_mode:c,count_include_pad:e,storage_order:f,dilations:[h,l],kernel_shape:[m,q],pads:[r,t,v,w],strides:[g,p]})},939161:(a,b)=>{d.sa("GlobalMaxPool",a,{format:b?"NHWC":"NCHW"})},939248:(a,b,c,e,f,h,l,m,q,r,t,v,w,g,p,u)=>{d.sa("MaxPool",a,{format:u?
"NHWC":"NCHW",auto_pad:b,ceil_mode:c,count_include_pad:e,storage_order:f,dilations:[h,l],kernel_shape:[m,q],pads:[r,t,v,w],strides:[g,p]})},939528:(a,b)=>{d.sa("GlobalMaxPool",a,{format:b?"NHWC":"NCHW"})},939615:(a,b,c,e,f,h,l,m,q,r,t,v,w,g,p,u)=>{d.sa("MaxPool",a,{format:u?"NHWC":"NCHW",auto_pad:b,ceil_mode:c,count_include_pad:e,storage_order:f,dilations:[h,l],kernel_shape:[m,q],pads:[r,t,v,w],strides:[g,p]})},939895:(a,b,c,e,f)=>{d.sa("Gemm",a,{alpha:b,beta:c,transA:e,transB:f})},939999:a=>{d.sa("MatMul",
a,void 0)},940053:(a,b,c,e)=>{d.sa("ArgMax",a,{keepDims:!!b,selectLastIndex:!!c,axis:e})},940161:(a,b,c,e)=>{d.sa("ArgMin",a,{keepDims:!!b,selectLastIndex:!!c,axis:e})},940269:(a,b)=>{d.sa("Softmax",a,{axis:b})},940332:(a,b)=>{d.sa("Concat",a,{axis:b})},940392:(a,b,c,e,f)=>{d.sa("Split",a,{axis:b,numOutputs:c,splitSizes:e?Array.from(N.subarray(e>>>0,f>>>0)):[]})},940532:a=>{d.sa("Expand",a,void 0)},940586:(a,b)=>{d.sa("Gather",a,{axis:Number(b)})},940657:(a,b)=>{d.sa("GatherElements",a,{axis:Number(b)})},
940736:(a,b,c,e,f,h,l,m,q,r,t)=>{d.sa("Resize",a,{antialias:b,axes:c?Array.from(N.subarray(c>>>0,e>>>0)):[],coordinateTransformMode:T(f),cubicCoeffA:h,excludeOutside:l,extrapolationValue:m,keepAspectRatioPolicy:T(q),mode:T(r),nearestMode:T(t)})},941082:(a,b,c,e,f,h,l)=>{d.sa("Slice",a,{starts:b?Array.from(N.subarray(b>>>0,c>>>0)):[],ends:e?Array.from(N.subarray(e>>>0,f>>>0)):[],axes:h?Array.from(N.subarray(h>>>0,l>>>0)):[]})},941298:a=>{d.sa("Tile",a,void 0)},941350:(a,b,c)=>{d.sa("LayerNormalization",
a,{axis:Number(b),epsilon:Number(c)})},941457:(a,b,c)=>{d.sa("InstanceNormalization",a,{epsilon:b,format:c?"NHWC":"NCHW"})},941571:(a,b,c)=>{d.sa("InstanceNormalization",a,{epsilon:b,format:c?"NHWC":"NCHW"})},941685:a=>{d.sa("Range",a,void 0)},941738:(a,b)=>{d.sa("Einsum",a,{equation:T(b)})},941819:(a,b,c,e,f)=>{d.sa("Pad",a,{mode:b,value:c,pads:e?Array.from(N.subarray(e>>>0,f>>>0)):[]})},941946:(a,b,c,e,f,h)=>{d.sa("BatchNormalization",a,{epsilon:b,momentum:c,spatial:!!f,trainingMode:!!e,format:h?
"NHWC":"NCHW"})},942115:(a,b,c,e,f,h)=>{d.sa("BatchNormalization",a,{epsilon:b,momentum:c,spatial:!!f,trainingMode:!!e,format:h?"NHWC":"NCHW"})},942284:(a,b,c)=>{d.sa("CumSum",a,{exclusive:Number(b),reverse:Number(c)})},942381:(a,b,c,e,f,h,l,m,q)=>{d.sa("Attention",a,{numHeads:b,isUnidirectional:c,maskFilterValue:e,scale:f,doRotary:h,qkvHiddenSizes:l?Array.from(N.subarray(Number(m)>>>0,Number(m)+l>>>0)):[],pastPresentShareBuffer:!!q})},942653:a=>{d.sa("BiasAdd",a,void 0)},942708:a=>{d.sa("BiasSplitGelu",
a,void 0)},942769:a=>{d.sa("FastGelu",a,void 0)},942825:(a,b,c,e,f,h,l,m,q,r,t,v,w)=>{d.sa("Conv",a,{format:q?"NHWC":"NCHW",auto_pad:b,dilations:[c],group:e,kernel_shape:[f],pads:h?Array.from(N.subarray(h>>>0,l>>>0)):[],strides:[m],w_is_const:()=>!!L[r>>>0],activation:T(t),activation_params:v?Array.from(ja.subarray(v>>>0,w>>>0)):[]})},943195:(a,b,c,e,f,h,l,m,q,r,t,v,w,g,p,u)=>{d.sa("Conv",a,{format:v?"NHWC":"NCHW",auto_pad:b,dilations:[c,e],group:f,kernel_shape:[h,l],pads:m?Array.from(N.subarray(m>>>
0,q>>>0)):[],strides:[r,t],w_is_const:()=>!!L[w>>>0],activation:T(g),activation_params:p?Array.from(ja.subarray(p>>>0,u>>>0)):[]})},943586:a=>{d.sa("Gelu",a,void 0)},943638:(a,b,c,e,f,h)=>{d.sa("MatMulNBits",a,{k:b,n:c,accuracyLevel:e,bits:f,blockSize:h})},943765:(a,b,c,e,f,h)=>{d.sa("MultiHeadAttention",a,{numHeads:b,isUnidirectional:c,maskFilterValue:e,scale:f,doRotary:h})},943924:(a,b)=>{d.sa("SkipLayerNormalization",a,{epsilon:b})},944005:a=>{d.Wa(a)},944039:(a,b)=>d.Ya(a,b,d.Ea.Za,d.Ea.errors)};
function xa(a){this.name="ExitStatus";this.message=`Program terminated with exit(${a})`;this.status=a}function ya(a){this.Ja=a-24;this.Pa=function(b){O[this.Ja+4>>>2>>>0]=b};this.Oa=function(b){O[this.Ja+8>>>2>>>0]=b};this.eb=function(b,c){this.Na();this.Pa(b);this.Oa(c)};this.Na=function(){O[this.Ja+16>>>2>>>0]=0}}
var za=0,Aa=0,Ba="undefined"!=typeof TextDecoder?new TextDecoder("utf8"):void 0,Ca=(a,b,c)=>{b>>>=0;var e=b+c;for(c=b;a[c]&&!(c>=e);)++c;if(16<c-b&&a.buffer&&Ba)return Ba.decode(a.subarray(b,c));for(e="";b<c;){var f=a[b++];if(f&128){var h=a[b++]&63;if(192==(f&224))e+=String.fromCharCode((f&31)<<6|h);else{var l=a[b++]&63;f=224==(f&240)?(f&15)<<12|h<<6|l:(f&7)<<18|h<<12|l<<6|a[b++]&63;65536>f?e+=String.fromCharCode(f):(f-=65536,e+=String.fromCharCode(55296|f>>10,56320|f&1023))}}else e+=String.fromCharCode(f)}return e},
T=(a,b)=>(a>>>=0)?Ca(M,a,b):"",Da=a=>{for(var b=0,c=0;c<a.length;++c){var e=a.charCodeAt(c);127>=e?b++:2047>=e?b+=2:55296<=e&&57343>=e?(b+=4,++c):b+=3}return b},Ea=(a,b,c,e)=>{c>>>=0;if(!(0<e))return 0;var f=c;e=c+e-1;for(var h=0;h<a.length;++h){var l=a.charCodeAt(h);if(55296<=l&&57343>=l){var m=a.charCodeAt(++h);l=65536+((l&1023)<<10)|m&1023}if(127>=l){if(c>=e)break;b[c++>>>0]=l}else{if(2047>=l){if(c+1>=e)break;b[c++>>>0]=192|l>>6}else{if(65535>=l){if(c+2>=e)break;b[c++>>>0]=224|l>>12}else{if(c+
3>=e)break;b[c++>>>0]=240|l>>18;b[c++>>>0]=128|l>>12&63}b[c++>>>0]=128|l>>6&63}b[c++>>>0]=128|l&63}}b[c>>>0]=0;return c-f},U=a=>0===a%4&&(0!==a%100||0===a%400),Fa=[0,31,60,91,121,152,182,213,244,274,305,335],Ga=[0,31,59,90,120,151,181,212,243,273,304,334],Ia=a=>{var b=Da(a)+1,c=Ha(b);c&&Ea(a,M,c,b);return c},Ja=[],Ka=(a,b)=>{Ja.length=0;for(var c;c=M[a++>>>0];){var e=105!=c;e&=112!=c;b+=e&&b%8?4:0;Ja.push(112==c?O[b>>>2>>>0]:105==c?N[b>>>2>>>0]:ka[b>>>3>>>0]);b+=e?8:4}return Ja},La={},Oa=()=>{if(!Na){var a=
{USER:"web_user",LOGNAME:"web_user",PATH:"/",PWD:"/",HOME:"/home/web_user",LANG:("object"==typeof navigator&&navigator.languages&&navigator.languages[0]||"C").replace("-","_")+".UTF-8",_:ea||"./this.program"},b;for(b in La)void 0===La[b]?delete a[b]:a[b]=La[b];var c=[];for(b in a)c.push(`${b}=${a[b]}`);Na=c}return Na},Na,Pa=[null,[],[]],Qa=[31,29,31,30,31,30,31,31,30,31,30,31],Ra=[31,28,31,30,31,30,31,31,30,31,30,31];function Sa(a){var b=Array(Da(a)+1);Ea(a,b,0,b.length);return b}
function Ta(a,b,c,e){function f(g,p,u){for(g="number"==typeof g?g.toString():g||"";g.length<p;)g=u[0]+g;return g}function h(g,p){return f(g,p,"0")}function l(g,p){function u(Ma){return 0>Ma?-1:0<Ma?1:0}var F;0===(F=u(g.getFullYear()-p.getFullYear()))&&0===(F=u(g.getMonth()-p.getMonth()))&&(F=u(g.getDate()-p.getDate()));return F}function m(g){switch(g.getDay()){case 0:return new Date(g.getFullYear()-1,11,29);case 1:return g;case 2:return new Date(g.getFullYear(),0,3);case 3:return new Date(g.getFullYear(),
0,2);case 4:return new Date(g.getFullYear(),0,1);case 5:return new Date(g.getFullYear()-1,11,31);case 6:return new Date(g.getFullYear()-1,11,30)}}function q(g){var p=g.Ca;for(g=new Date((new Date(g.Da+1900,0,1)).getTime());0<p;){var u=g.getMonth(),F=(U(g.getFullYear())?Qa:Ra)[u];if(p>F-g.getDate())p-=F-g.getDate()+1,g.setDate(1),11>u?g.setMonth(u+1):(g.setMonth(0),g.setFullYear(g.getFullYear()+1));else{g.setDate(g.getDate()+p);break}}u=new Date(g.getFullYear()+1,0,4);p=m(new Date(g.getFullYear(),
0,4));u=m(u);return 0>=l(p,g)?0>=l(u,g)?g.getFullYear()+1:g.getFullYear():g.getFullYear()-1}a>>>=0;b>>>=0;c>>>=0;e>>>=0;var r=O[e+40>>>2>>>0];e={bb:N[e>>>2>>>0],ab:N[e+4>>>2>>>0],Ga:N[e+8>>>2>>>0],Ka:N[e+12>>>2>>>0],Ha:N[e+16>>>2>>>0],Da:N[e+20>>>2>>>0],xa:N[e+24>>>2>>>0],Ca:N[e+28>>>2>>>0],fb:N[e+32>>>2>>>0],$a:N[e+36>>>2>>>0],cb:r?T(r):""};c=T(c);r={"%c":"%a %b %d %H:%M:%S %Y","%D":"%m/%d/%y","%F":"%Y-%m-%d","%h":"%b","%r":"%I:%M:%S %p","%R":"%H:%M","%T":"%H:%M:%S","%x":"%m/%d/%y","%X":"%H:%M:%S",
"%Ec":"%c","%EC":"%C","%Ex":"%m/%d/%y","%EX":"%H:%M:%S","%Ey":"%y","%EY":"%Y","%Od":"%d","%Oe":"%e","%OH":"%H","%OI":"%I","%Om":"%m","%OM":"%M","%OS":"%S","%Ou":"%u","%OU":"%U","%OV":"%V","%Ow":"%w","%OW":"%W","%Oy":"%y"};for(var t in r)c=c.replace(new RegExp(t,"g"),r[t]);var v="Sunday Monday Tuesday Wednesday Thursday Friday Saturday".split(" "),w="January February March April May June July August September October November December".split(" ");r={"%a":g=>v[g.xa].substring(0,3),"%A":g=>v[g.xa],"%b":g=>
w[g.Ha].substring(0,3),"%B":g=>w[g.Ha],"%C":g=>h((g.Da+1900)/100|0,2),"%d":g=>h(g.Ka,2),"%e":g=>f(g.Ka,2," "),"%g":g=>q(g).toString().substring(2),"%G":g=>q(g),"%H":g=>h(g.Ga,2),"%I":g=>{g=g.Ga;0==g?g=12:12<g&&(g-=12);return h(g,2)},"%j":g=>{for(var p=0,u=0;u<=g.Ha-1;p+=(U(g.Da+1900)?Qa:Ra)[u++]);return h(g.Ka+p,3)},"%m":g=>h(g.Ha+1,2),"%M":g=>h(g.ab,2),"%n":()=>"\n","%p":g=>0<=g.Ga&&12>g.Ga?"AM":"PM","%S":g=>h(g.bb,2),"%t":()=>"\t","%u":g=>g.xa||7,"%U":g=>h(Math.floor((g.Ca+7-g.xa)/7),2),"%V":g=>
{var p=Math.floor((g.Ca+7-(g.xa+6)%7)/7);2>=(g.xa+371-g.Ca-2)%7&&p++;if(p)53==p&&(u=(g.xa+371-g.Ca)%7,4==u||3==u&&U(g.Da)||(p=1));else{p=52;var u=(g.xa+7-g.Ca-1)%7;(4==u||5==u&&U(g.Da%400-1))&&p++}return h(p,2)},"%w":g=>g.xa,"%W":g=>h(Math.floor((g.Ca+7-(g.xa+6)%7)/7),2),"%y":g=>(g.Da+1900).toString().substring(2),"%Y":g=>g.Da+1900,"%z":g=>{g=g.$a;var p=0<=g;g=Math.abs(g)/60;return(p?"+":"-")+String("0000"+(g/60*100+g%60)).slice(-4)},"%Z":g=>g.cb,"%%":()=>"%"};c=c.replace(/%%/g,"\x00\x00");for(t in r)c.includes(t)&&
(c=c.replace(new RegExp(t,"g"),r[t](e)));c=c.replace(/\0\0/g,"%");t=Sa(c);if(t.length>b)return 0;L.set(t,a>>>0);return t.length-1}var V=a=>{try{a()}catch(b){H(b)}};function Ua(){var a=W,b={};for(let [c,e]of Object.entries(a))b[c]="function"==typeof e?function(){X.push(c);try{return e.apply(null,arguments)}finally{J||(X.pop(),n&&1===Y&&0===X.length&&(Y=0,V(Va),"undefined"!=typeof Fibers&&Fibers.gb()))}}:e;return b}var Y=0,n=null,Wa=0,X=[],Xa={},Ya={},Za=0,$a=null,ab=[];
function ba(){return new Promise((a,b)=>{$a={resolve:a,reject:b}})}function bb(){var a=Ha(65548),b=a+12;O[a>>>2>>>0]=b;O[a+4>>>2>>>0]=b+65536;b=X[0];var c=Xa[b];void 0===c&&(c=Za++,Xa[b]=c,Ya[c]=b);N[a+8>>>2>>>0]=c;return a}
function cb(a){if(!J){if(0===Y){var b=!1,c=!1;a((e=0)=>{if(!J&&(Wa=e,b=!0,c)){Y=2;V(()=>db(n));"undefined"!=typeof Browser&&Browser.Ia.Qa&&Browser.Ia.resume();e=!1;try{var f=(0,W[Ya[N[n+8>>>2>>>0]]])()}catch(m){f=m,e=!0}var h=!1;if(!n){var l=$a;l&&($a=null,(e?l.reject:l.resolve)(f),h=!0)}if(e&&!h)throw f;}});c=!0;b||(Y=1,n=bb(),"undefined"!=typeof Browser&&Browser.Ia.Qa&&Browser.Ia.pause(),V(()=>eb(n)))}else 2===Y?(Y=0,V(fb),gb(n),n=null,ab.forEach(e=>{if(!J)try{e();try{K=K=e=K,d.onExit?.(e),J=!0,
x(e,new xa(e))}catch(f){f instanceof xa||"unwind"==f||x(1,f)}}catch(f){f instanceof xa||"unwind"==f||x(1,f)}})):H(`invalid state: ${Y}`);return Wa}}function hb(a){return cb(b=>{a().then(b)})}
var kb={n:function(a,b,c){return hb(async()=>{await d.Ua(a,b,c)})},a:function(a,b,c){a>>>=0;(new ya(a)).eb(b>>>0,c>>>0);za=a;Aa++;throw za;},g:function(){return 0},J:function(){},A:function(){},C:function(){},L:function(){return 0},H:function(){},D:function(){},G:function(){},l:function(){},B:function(){},y:function(){},I:function(){},z:function(){},m:()=>1,q:function(a,b,c){a=b+2097152>>>0<4194305-!!a?(a>>>0)+4294967296*b:NaN;c>>>=0;a=new Date(1E3*a);N[c>>>2>>>0]=a.getUTCSeconds();N[c+4>>>2>>>0]=
a.getUTCMinutes();N[c+8>>>2>>>0]=a.getUTCHours();N[c+12>>>2>>>0]=a.getUTCDate();N[c+16>>>2>>>0]=a.getUTCMonth();N[c+20>>>2>>>0]=a.getUTCFullYear()-1900;N[c+24>>>2>>>0]=a.getUTCDay();N[c+28>>>2>>>0]=(a.getTime()-Date.UTC(a.getUTCFullYear(),0,1,0,0,0,0))/864E5|0},r:function(a,b,c){a=b+2097152>>>0<4194305-!!a?(a>>>0)+4294967296*b:NaN;c>>>=0;a=new Date(1E3*a);N[c>>>2>>>0]=a.getSeconds();N[c+4>>>2>>>0]=a.getMinutes();N[c+8>>>2>>>0]=a.getHours();N[c+12>>>2>>>0]=a.getDate();N[c+16>>>2>>>0]=a.getMonth();
N[c+20>>>2>>>0]=a.getFullYear()-1900;N[c+24>>>2>>>0]=a.getDay();N[c+28>>>2>>>0]=(U(a.getFullYear())?Fa:Ga)[a.getMonth()]+a.getDate()-1|0;N[c+36>>>2>>>0]=-(60*a.getTimezoneOffset());b=(new Date(a.getFullYear(),6,1)).getTimezoneOffset();var e=(new Date(a.getFullYear(),0,1)).getTimezoneOffset();N[c+32>>>2>>>0]=(b!=e&&a.getTimezoneOffset()==Math.min(e,b))|0},s:function(a){a>>>=0;var b=new Date(N[a+20>>>2>>>0]+1900,N[a+16>>>2>>>0],N[a+12>>>2>>>0],N[a+8>>>2>>>0],N[a+4>>>2>>>0],N[a>>>2>>>0],0),c=N[a+32>>>
2>>>0],e=b.getTimezoneOffset(),f=(new Date(b.getFullYear(),6,1)).getTimezoneOffset(),h=(new Date(b.getFullYear(),0,1)).getTimezoneOffset(),l=Math.min(h,f);0>c?N[a+32>>>2>>>0]=Number(f!=h&&l==e):0<c!=(l==e)&&(f=Math.max(h,f),b.setTime(b.getTime()+6E4*((0<c?l:f)-e)));N[a+24>>>2>>>0]=b.getDay();N[a+28>>>2>>>0]=(U(b.getFullYear())?Fa:Ga)[b.getMonth()]+b.getDate()-1|0;N[a>>>2>>>0]=b.getSeconds();N[a+4>>>2>>>0]=b.getMinutes();N[a+8>>>2>>>0]=b.getHours();N[a+12>>>2>>>0]=b.getDate();N[a+16>>>2>>>0]=b.getMonth();
N[a+20>>>2>>>0]=b.getYear();a=b.getTime();isNaN(a)?(N[ib()>>>2>>>0]=61,a=-1):a/=1E3;return jb((S=a,1<=+Math.abs(S)?0<S?+Math.floor(S/4294967296)>>>0:~~+Math.ceil((S-+(~~S>>>0))/4294967296)>>>0:0)),a>>>0},o:function(){return-52},p:function(){},w:function(a,b,c){function e(q){return(q=q.toTimeString().match(/\(([A-Za-z ]+)\)$/))?q[1]:"GMT"}c>>>=0;var f=(new Date).getFullYear(),h=new Date(f,0,1),l=new Date(f,6,1);f=h.getTimezoneOffset();var m=l.getTimezoneOffset();O[a>>>0>>>2>>>0]=60*Math.max(f,m);N[b>>>
0>>>2>>>0]=Number(f!=m);a=e(h);b=e(l);a=Ia(a);b=Ia(b);m<f?(O[c>>>2>>>0]=a,O[c+4>>>2>>>0]=b):(O[c>>>2>>>0]=b,O[c+4>>>2>>>0]=a)},e:()=>{H("")},b:function(a,b,c){a>>>=0;b=Ka(b>>>0,c>>>0);return wa[a].apply(null,b)},i:function(a,b,c){a>>>=0;b=Ka(b>>>0,c>>>0);return wa[a].apply(null,b)},h:()=>Date.now(),x:function(){return 4294901760},c:()=>performance.now(),K:function(a,b,c){b>>>=0;return M.copyWithin(a>>>0>>>0,b>>>0,b+(c>>>0)>>>0)},u:function(a){a>>>=0;var b=M.length;if(4294901760<a)return!1;for(var c=
1;4>=c;c*=2){var e=b*(1+.2/c);e=Math.min(e,a+100663296);var f=Math;e=Math.max(a,e);a:{f=(f.min.call(f,4294901760,e+(65536-e%65536)%65536)-I.buffer.byteLength+65535)/65536;try{I.grow(f);la();var h=1;break a}catch(l){}h=void 0}if(h)return!0}return!1},E:function(a,b){a>>>=0;b>>>=0;var c=0;Oa().forEach((e,f)=>{var h=b+c;f=O[a+4*f>>>2>>>0]=h;for(h=0;h<e.length;++h)L[f++>>>0>>>0]=e.charCodeAt(h);L[f>>>0>>>0]=0;c+=e.length+1});return 0},F:function(a,b){a>>>=0;b>>>=0;var c=Oa();O[a>>>2>>>0]=c.length;var e=
0;c.forEach(f=>e+=f.length+1);O[b>>>2>>>0]=e;return 0},f:()=>52,k:function(){return 52},t:function(){return 70},j:function(a,b,c,e){b>>>=0;c>>>=0;e>>>=0;for(var f=0,h=0;h<c;h++){var l=O[b>>>2>>>0],m=O[b+4>>>2>>>0];b+=8;for(var q=0;q<m;q++){var r=M[l+q>>>0],t=Pa[a];0===r||10===r?((1===a?ia:G)(Ca(t,0)),t.length=0):t.push(r)}f+=m}O[e>>>2>>>0]=f;return 0},v:Ta,d:function(a,b,c,e){return Ta(a>>>0,b>>>0,c>>>0,e>>>0)}},W=function(){function a(c){W=c.exports;W=Ua();W=lb();I=W.M;la();na.unshift(W.N);P--;0==
P&&(null!==pa&&(clearInterval(pa),pa=null),Q&&(c=Q,Q=null,c()));return W}var b={a:kb};P++;if(d.instantiateWasm)try{return d.instantiateWasm(b,a)}catch(c){G(`Module.instantiateWasm callback failed with error: ${c}`),k(c)}va(b,function(c){a(c.instance)}).catch(k);return{}}();d._OrtInit=(a,b)=>(d._OrtInit=W.O)(a,b);d._OrtGetLastError=(a,b)=>(d._OrtGetLastError=W.P)(a,b);d._OrtCreateSessionOptions=(a,b,c,e,f,h,l,m,q,r)=>(d._OrtCreateSessionOptions=W.Q)(a,b,c,e,f,h,l,m,q,r);
d._OrtAppendExecutionProvider=(a,b)=>(d._OrtAppendExecutionProvider=W.R)(a,b);d._OrtAddFreeDimensionOverride=(a,b,c)=>(d._OrtAddFreeDimensionOverride=W.S)(a,b,c);d._OrtAddSessionConfigEntry=(a,b,c)=>(d._OrtAddSessionConfigEntry=W.T)(a,b,c);d._OrtReleaseSessionOptions=a=>(d._OrtReleaseSessionOptions=W.U)(a);d._OrtCreateSession=(a,b,c)=>(d._OrtCreateSession=W.V)(a,b,c);d._OrtReleaseSession=a=>(d._OrtReleaseSession=W.W)(a);d._OrtGetInputOutputCount=(a,b,c)=>(d._OrtGetInputOutputCount=W.X)(a,b,c);
d._OrtGetInputName=(a,b)=>(d._OrtGetInputName=W.Y)(a,b);d._OrtGetOutputName=(a,b)=>(d._OrtGetOutputName=W.Z)(a,b);d._OrtFree=a=>(d._OrtFree=W._)(a);d._OrtCreateTensor=(a,b,c,e,f,h)=>(d._OrtCreateTensor=W.$)(a,b,c,e,f,h);d._OrtGetTensorData=(a,b,c,e,f)=>(d._OrtGetTensorData=W.aa)(a,b,c,e,f);d._OrtReleaseTensor=a=>(d._OrtReleaseTensor=W.ba)(a);d._OrtCreateRunOptions=(a,b,c,e)=>(d._OrtCreateRunOptions=W.ca)(a,b,c,e);d._OrtAddRunConfigEntry=(a,b,c)=>(d._OrtAddRunConfigEntry=W.da)(a,b,c);
d._OrtReleaseRunOptions=a=>(d._OrtReleaseRunOptions=W.ea)(a);d._OrtCreateBinding=a=>(d._OrtCreateBinding=W.fa)(a);d._OrtBindInput=(a,b,c)=>(d._OrtBindInput=W.ga)(a,b,c);d._OrtBindOutput=(a,b,c,e)=>(d._OrtBindOutput=W.ha)(a,b,c,e);d._OrtClearBoundOutputs=a=>(d._OrtClearBoundOutputs=W.ia)(a);d._OrtReleaseBinding=a=>(d._OrtReleaseBinding=W.ja)(a);d._OrtRunWithBinding=(a,b,c,e,f)=>(d._OrtRunWithBinding=W.ka)(a,b,c,e,f);d._OrtRun=(a,b,c,e,f,h,l,m)=>(d._OrtRun=W.la)(a,b,c,e,f,h,l,m);
d._OrtEndProfiling=a=>(d._OrtEndProfiling=W.ma)(a);d._JsepOutput=(a,b,c)=>(d._JsepOutput=W.na)(a,b,c);d._JsepGetNodeName=a=>(d._JsepGetNodeName=W.oa)(a);var ib=()=>(ib=W.pa)(),Ha=d._malloc=a=>(Ha=d._malloc=W.qa)(a),gb=d._free=a=>(gb=d._free=W.ra)(a),jb=a=>(jb=W.ta)(a),mb=()=>(mb=W.ua)(),nb=a=>(nb=W.va)(a),ob=a=>(ob=W.wa)(a),eb=a=>(eb=W.ya)(a),Va=()=>(Va=W.za)(),db=a=>(db=W.Aa)(a),fb=()=>(fb=W.Ba)();d.___start_em_js=944151;d.___stop_em_js=944312;
function lb(){var a=W;a=Object.assign({},a);var b=e=>()=>e()>>>0,c=e=>f=>e(f)>>>0;a.pa=b(a.pa);a.qa=c(a.qa);a.ua=b(a.ua);a.wa=c(a.wa);return a}d.stackAlloc=ob;d.stackSave=mb;d.stackRestore=nb;d.UTF8ToString=T;d.stringToUTF8=(a,b,c)=>Ea(a,M,b,c);d.lengthBytesUTF8=Da;var Z;Q=function pb(){Z||qb();Z||(Q=pb)};
function qb(){if(!(0<P)){if(d.preRun)for("function"==typeof d.preRun&&(d.preRun=[d.preRun]);d.preRun.length;){var a=d.preRun.shift();ma.unshift(a)}for(;0<ma.length;)ma.shift()(d);if(!(0<P||Z||(Z=!0,d.calledRun=!0,J))){for(;0<na.length;)na.shift()(d);for(aa(d);0<oa.length;)oa.shift()(d)}}}qb();


  return moduleArg.ready
}
);
})();
;
if (typeof exports === 'object' && typeof module === 'object')
  module.exports = ortWasm;
else if (typeof define === 'function' && define['amd'])
  define([], () => ortWasm);
