
var ortWasmThreaded = (() => {
  var _scriptDir = typeof document !== 'undefined' && document.currentScript ? document.currentScript.src : undefined;
  if (typeof __filename !== 'undefined') _scriptDir = _scriptDir || __filename;
  return (
function(moduleArg = {}) {

function d(){l.buffer!=m.buffer&&p();return m}function v(){l.buffer!=m.buffer&&p();return aa}function z(){l.buffer!=m.buffer&&p();return ba}function A(){l.buffer!=m.buffer&&p();return ca}function da(){l.buffer!=m.buffer&&p();return ea}function fa(){l.buffer!=m.buffer&&p();return ha}var B=moduleArg,ia,C;B.ready=new Promise((a,b)=>{ia=a;C=b});"use strict";B.mountExternalData=(a,b)=>{(B.cb||(B.cb=new Map)).set(a,b)};B.unmountExternalData=()=>{delete B.cb};
let ka=()=>{const a=(c,e,f)=>(...g)=>{const k=D,n=e?.();g=c(...g);const t=e?.();n!==t&&(c=t,f(n),e=f=null);return D!=k?ja():g},b=c=>async(...e)=>{try{if(B.bb)throw Error("Session already started");const f=B.bb={Gb:e[0],errors:[]},g=await c(...e);if(B.bb!==f)throw Error("Session mismatch");B.kb?.flush();const k=f.errors;if(0<k.length){let n=await Promise.all(k);n=n.filter(t=>t);if(0<n.length)throw Error(n.join("\n"));}return g}finally{B.bb=null}};B._OrtCreateSession=a(B._OrtCreateSession,()=>B._OrtCreateSession,
c=>B._OrtCreateSession=c);B._OrtRun=b(a(B._OrtRun,()=>B._OrtRun,c=>B._OrtRun=c));B._OrtRunWithBinding=b(a(B._OrtRunWithBinding,()=>B._OrtRunWithBinding,c=>B._OrtRunWithBinding=c));B._OrtBindInput=a(B._OrtBindInput,()=>B._OrtBindInput,c=>B._OrtBindInput=c);ka=void 0};
B.jsepInit=(a,b)=>{ka?.();if("webgpu"===a){[B.kb,B.xb,B.Bb,B.lb,B.Ab,B.Ea,B.Cb,B.Eb,B.yb,B.zb,B.Db]=b;const c=B.kb;B.jsepRegisterBuffer=(e,f,g,k)=>c.registerBuffer(e,f,g,k);B.jsepGetBuffer=e=>c.getBuffer(e);B.jsepCreateDownloader=(e,f,g)=>c.createDownloader(e,f,g);B.jsepOnReleaseSession=e=>{c.onReleaseSession(e)};B.jsepOnRunStart=e=>c.onRunStart(e)}};
var la=Object.assign({},B),ma="./this.program",na=(a,b)=>{throw b;},oa="object"==typeof window,E="function"==typeof importScripts,F="object"==typeof process&&"object"==typeof process.versions&&"string"==typeof process.versions.node,G=B.ENVIRONMENT_IS_PTHREAD||!1,H="";function pa(a){return B.locateFile?B.locateFile(a,H):H+a}var qa,I,J;
if(F){var fs=require("fs"),ra=require("path");H=E?ra.dirname(H)+"/":__dirname+"/";qa=(b,c)=>{b=sa(b)?new URL(b):ra.normalize(b);return fs.readFileSync(b,c?void 0:"utf8")};J=b=>{b=qa(b,!0);b.buffer||(b=new Uint8Array(b));return b};I=(b,c,e,f=!0)=>{b=sa(b)?new URL(b):ra.normalize(b);fs.readFile(b,f?void 0:"utf8",(g,k)=>{g?e(g):c(f?k.buffer:k)})};!B.thisProgram&&1<process.argv.length&&(ma=process.argv[1].replace(/\\/g,"/"));process.argv.slice(2);na=(b,c)=>{process.exitCode=b;throw c;};B.inspect=()=>
"[Emscripten Module object]";let a;try{a=require("worker_threads")}catch(b){throw console.error('The "worker_threads" module is not supported in this node.js build - perhaps a newer version is needed?'),b;}global.Worker=a.Worker}else if(oa||E)E?H=self.location.href:"undefined"!=typeof document&&document.currentScript&&(H=document.currentScript.src),(typeof _scriptDir !== "undefined" && _scriptDir)&&(H=_scriptDir),0!==H.indexOf("blob:")?H=H.substr(0,H.replace(/[?#].*/,"").lastIndexOf("/")+1):H="",F||(qa=a=>{var b=new XMLHttpRequest;b.open("GET",
a,!1);b.send(null);return b.responseText},E&&(J=a=>{var b=new XMLHttpRequest;b.open("GET",a,!1);b.responseType="arraybuffer";b.send(null);return new Uint8Array(b.response)}),I=(a,b,c)=>{var e=new XMLHttpRequest;e.open("GET",a,!0);e.responseType="arraybuffer";e.onload=()=>{200==e.status||0==e.status&&e.response?b(e.response):c()};e.onerror=c;e.send(null)});F&&"undefined"==typeof performance&&(global.performance=require("perf_hooks").performance);var ta=console.log.bind(console),ua=console.error.bind(console);
F&&(ta=(...a)=>fs.writeSync(1,a.join(" ")+"\n"),ua=(...a)=>fs.writeSync(2,a.join(" ")+"\n"));var va=ta,K=ua;Object.assign(B,la);la=null;"object"!=typeof WebAssembly&&L("no native wasm support detected");var l,wa,M=!1,O,m,aa,ba,ca,ea,ha;
function p(){var a=l.buffer;B.HEAP8=m=new Int8Array(a);B.HEAP16=new Int16Array(a);B.HEAPU8=aa=new Uint8Array(a);B.HEAPU16=new Uint16Array(a);B.HEAP32=ba=new Int32Array(a);B.HEAPU32=ca=new Uint32Array(a);B.HEAPF32=ea=new Float32Array(a);B.HEAPF64=ha=new Float64Array(a)}var xa=16777216;
if(G)l=B.wasmMemory;else if(B.wasmMemory)l=B.wasmMemory;else if(l=new WebAssembly.Memory({initial:xa/65536,maximum:65536,shared:!0}),!(l.buffer instanceof SharedArrayBuffer))throw K("requested a shared WebAssembly.Memory but the returned buffer is not a SharedArrayBuffer, indicating that while the browser has SharedArrayBuffer it does not have WebAssembly threads support - you may need to set a flag"),F&&K("(on node you may need: --experimental-wasm-threads --experimental-wasm-bulk-memory and/or recent version)"),
Error("bad memory");p();xa=l.buffer.byteLength;var ya=[],za=[],Aa=[],P=0,Ba=null,Q=null;function Ca(){P--;if(0==P&&(null!==Ba&&(clearInterval(Ba),Ba=null),Q)){var a=Q;Q=null;a()}}function L(a){a="Aborted("+a+")";K(a);M=!0;O=1;a=new WebAssembly.RuntimeError(a+". Build with -sASSERTIONS for more info.");C(a);throw a;}var Da=a=>a.startsWith("data:application/octet-stream;base64,"),sa=a=>a.startsWith("file://"),R;R="ort-wasm-simd-threaded.wasm";Da(R)||(R=pa(R));
function Ea(a){if(J)return J(a);throw"both async and sync fetching of the wasm failed";}function Fa(a){if(oa||E){if("function"==typeof fetch&&!sa(a))return fetch(a,{credentials:"same-origin"}).then(b=>{if(!b.ok)throw"failed to load wasm binary file at '"+a+"'";return b.arrayBuffer()}).catch(()=>Ea(a));if(I)return new Promise((b,c)=>{I(a,e=>b(new Uint8Array(e)),c)})}return Promise.resolve().then(()=>Ea(a))}
function Ga(a,b,c){return Fa(a).then(e=>WebAssembly.instantiate(e,b)).then(e=>e).then(c,e=>{K(`failed to asynchronously prepare wasm: ${e}`);L(e)})}function Ha(a,b){var c=R;return"function"!=typeof WebAssembly.instantiateStreaming||Da(c)||sa(c)||F||"function"!=typeof fetch?Ga(c,a,b):fetch(c,{credentials:"same-origin"}).then(e=>WebAssembly.instantiateStreaming(e,a).then(b,function(f){K(`wasm streaming compile failed: ${f}`);K("falling back to ArrayBuffer instantiation");return Ga(c,a,b)}))}
var S,Ia={933148:(a,b,c,e)=>{if("undefined"==typeof B||!B.cb)return 1;a=T(a>>>0);a.startsWith("./")&&(a=a.substring(2));a=B.cb.get(a);if(!a)return 2;b>>>=0;c>>>=0;e>>>=0;if(b+c>a.byteLength)return 3;try{return v().set(a.subarray(b,b+c),e>>>0),0}catch{return 4}},933649:()=>{B.yb()},933680:()=>{B.zb()},933709:()=>{B.Db()},933734:a=>B.xb(a),933767:a=>B.Bb(a),933799:(a,b,c)=>{B.lb(a,b,c,!0)},933838:(a,b,c)=>{B.lb(a,b,c)},933871:a=>{B.Ea("Abs",a,void 0)},933922:a=>{B.Ea("Neg",a,void 0)},933973:a=>{B.Ea("Floor",
a,void 0)},934026:a=>{B.Ea("Ceil",a,void 0)},934078:a=>{B.Ea("Reciprocal",a,void 0)},934136:a=>{B.Ea("Sqrt",a,void 0)},934188:a=>{B.Ea("Exp",a,void 0)},934239:a=>{B.Ea("Erf",a,void 0)},934290:a=>{B.Ea("Sigmoid",a,void 0)},934345:(a,b,c)=>{B.Ea("HardSigmoid",a,{alpha:b,beta:c})},934424:a=>{B.Ea("Log",a,void 0)},934475:a=>{B.Ea("Sin",a,void 0)},934526:a=>{B.Ea("Cos",a,void 0)},934577:a=>{B.Ea("Tan",a,void 0)},934628:a=>{B.Ea("Asin",a,void 0)},934680:a=>{B.Ea("Acos",a,void 0)},934732:a=>{B.Ea("Atan",
a,void 0)},934784:a=>{B.Ea("Sinh",a,void 0)},934836:a=>{B.Ea("Cosh",a,void 0)},934888:a=>{B.Ea("Asinh",a,void 0)},934941:a=>{B.Ea("Acosh",a,void 0)},934994:a=>{B.Ea("Atanh",a,void 0)},935047:a=>{B.Ea("Tanh",a,void 0)},935099:a=>{B.Ea("Not",a,void 0)},935150:(a,b,c)=>{B.Ea("Clip",a,{min:b,max:c})},935219:a=>{B.Ea("Clip",a,void 0)},935271:(a,b)=>{B.Ea("Elu",a,{alpha:b})},935329:a=>{B.Ea("Relu",a,void 0)},935381:(a,b)=>{B.Ea("LeakyRelu",a,{alpha:b})},935445:(a,b)=>{B.Ea("ThresholdedRelu",a,{alpha:b})},
935515:(a,b)=>{B.Ea("Cast",a,{to:b})},935573:a=>{B.Ea("Add",a,void 0)},935624:a=>{B.Ea("Sub",a,void 0)},935675:a=>{B.Ea("Mul",a,void 0)},935726:a=>{B.Ea("Div",a,void 0)},935777:a=>{B.Ea("Pow",a,void 0)},935828:a=>{B.Ea("Equal",a,void 0)},935881:a=>{B.Ea("Greater",a,void 0)},935936:a=>{B.Ea("GreaterOrEqual",a,void 0)},935998:a=>{B.Ea("Less",a,void 0)},936050:a=>{B.Ea("LessOrEqual",a,void 0)},936109:(a,b,c,e,f)=>{B.Ea("ReduceMean",a,{keepDims:!!b,noopWithEmptyAxes:!!c,axes:e?Array.from(z().subarray(e>>>
0,f>>>0)):[]})},936268:(a,b,c,e,f)=>{B.Ea("ReduceMax",a,{keepDims:!!b,noopWithEmptyAxes:!!c,axes:e?Array.from(z().subarray(e>>>0,f>>>0)):[]})},936426:(a,b,c,e,f)=>{B.Ea("ReduceMin",a,{keepDims:!!b,noopWithEmptyAxes:!!c,axes:e?Array.from(z().subarray(e>>>0,f>>>0)):[]})},936584:(a,b,c,e,f)=>{B.Ea("ReduceProd",a,{keepDims:!!b,noopWithEmptyAxes:!!c,axes:e?Array.from(z().subarray(e>>>0,f>>>0)):[]})},936743:(a,b,c,e,f)=>{B.Ea("ReduceSum",a,{keepDims:!!b,noopWithEmptyAxes:!!c,axes:e?Array.from(z().subarray(e>>>
0,f>>>0)):[]})},936901:(a,b,c,e,f)=>{B.Ea("ReduceL1",a,{keepDims:!!b,noopWithEmptyAxes:!!c,axes:e?Array.from(z().subarray(e>>>0,f>>>0)):[]})},937058:(a,b,c,e,f)=>{B.Ea("ReduceL2",a,{keepDims:!!b,noopWithEmptyAxes:!!c,axes:e?Array.from(z().subarray(e>>>0,f>>>0)):[]})},937215:(a,b,c,e,f)=>{B.Ea("ReduceLogSum",a,{keepDims:!!b,noopWithEmptyAxes:!!c,axes:e?Array.from(z().subarray(e>>>0,f>>>0)):[]})},937376:(a,b,c,e,f)=>{B.Ea("ReduceSumSquare",a,{keepDims:!!b,noopWithEmptyAxes:!!c,axes:e?Array.from(z().subarray(e>>>
0,f>>>0)):[]})},937540:(a,b,c,e,f)=>{B.Ea("ReduceLogSumExp",a,{keepDims:!!b,noopWithEmptyAxes:!!c,axes:e?Array.from(z().subarray(e>>>0,f>>>0)):[]})},937704:a=>{B.Ea("Where",a,void 0)},937757:(a,b,c)=>{B.Ea("Transpose",a,{perm:b?Array.from(z().subarray(b>>>0,c>>>0)):[]})},937865:(a,b,c,e,f,g,k,n,t,q,u,x,y,h,r)=>{B.Ea("ConvTranspose",a,{format:t?"NHWC":"NCHW",autoPad:b,dilations:[c],group:e,kernel_shape:[f],pads:[g,k],strides:[n],wIsConst:()=>!!d()[q>>>0],outputPadding:u?Array.from(z().subarray(u>>>
0,x>>>0)):[],outputShape:y?Array.from(z().subarray(y>>>0,h>>>0)):[],activation:T(r)})},938267:(a,b,c,e,f,g,k,n,t,q,u,x,y,h)=>{B.Ea("ConvTranspose",a,{format:n?"NHWC":"NCHW",autoPad:b,dilations:Array.from(z().subarray(c>>>0,(c>>>0)+2>>>0)),group:e,kernelShape:Array.from(z().subarray(f>>>0,(f>>>0)+2>>>0)),pads:Array.from(z().subarray(g>>>0,(g>>>0)+4>>>0)),strides:Array.from(z().subarray(k>>>0,(k>>>0)+2>>>0)),wIsConst:()=>!!d()[t>>>0],outputPadding:q?Array.from(z().subarray(q>>>0,u>>>0)):[],outputShape:x?
Array.from(z().subarray(x>>>0,y>>>0)):[],activation:T(h)})},938832:(a,b,c,e,f,g,k,n,t,q,u,x,y,h,r)=>{B.Ea("ConvTranspose",a,{format:t?"NHWC":"NCHW",autoPad:b,dilations:[c],group:e,kernel_shape:[f],pads:[g,k],strides:[n],wIsConst:()=>!!d()[q>>>0],outputPadding:u?Array.from(z().subarray(u>>>0,x>>>0)):[],outputShape:y?Array.from(z().subarray(y>>>0,h>>>0)):[],activation:T(r)})},939234:(a,b,c,e,f,g,k,n,t,q,u,x,y,h)=>{B.Ea("ConvTranspose",a,{format:n?"NHWC":"NCHW",autoPad:b,dilations:Array.from(z().subarray(c>>>
0,(c>>>0)+2>>>0)),group:e,kernelShape:Array.from(z().subarray(f>>>0,(f>>>0)+2>>>0)),pads:Array.from(z().subarray(g>>>0,(g>>>0)+4>>>0)),strides:Array.from(z().subarray(k>>>0,(k>>>0)+2>>>0)),wIsConst:()=>!!d()[t>>>0],outputPadding:q?Array.from(z().subarray(q>>>0,u>>>0)):[],outputShape:x?Array.from(z().subarray(x>>>0,y>>>0)):[],activation:T(h)})},939799:(a,b)=>{B.Ea("GlobalAveragePool",a,{format:b?"NHWC":"NCHW"})},939890:(a,b,c,e,f,g,k,n,t,q,u,x,y,h,r,w)=>{B.Ea("AveragePool",a,{format:w?"NHWC":"NCHW",
auto_pad:b,ceil_mode:c,count_include_pad:e,storage_order:f,dilations:[g,k],kernel_shape:[n,t],pads:[q,u,x,y],strides:[h,r]})},940174:(a,b)=>{B.Ea("GlobalAveragePool",a,{format:b?"NHWC":"NCHW"})},940265:(a,b,c,e,f,g,k,n,t,q,u,x,y,h,r,w)=>{B.Ea("AveragePool",a,{format:w?"NHWC":"NCHW",auto_pad:b,ceil_mode:c,count_include_pad:e,storage_order:f,dilations:[g,k],kernel_shape:[n,t],pads:[q,u,x,y],strides:[h,r]})},940549:(a,b)=>{B.Ea("GlobalMaxPool",a,{format:b?"NHWC":"NCHW"})},940636:(a,b,c,e,f,g,k,n,t,q,
u,x,y,h,r,w)=>{B.Ea("MaxPool",a,{format:w?"NHWC":"NCHW",auto_pad:b,ceil_mode:c,count_include_pad:e,storage_order:f,dilations:[g,k],kernel_shape:[n,t],pads:[q,u,x,y],strides:[h,r]})},940916:(a,b)=>{B.Ea("GlobalMaxPool",a,{format:b?"NHWC":"NCHW"})},941003:(a,b,c,e,f,g,k,n,t,q,u,x,y,h,r,w)=>{B.Ea("MaxPool",a,{format:w?"NHWC":"NCHW",auto_pad:b,ceil_mode:c,count_include_pad:e,storage_order:f,dilations:[g,k],kernel_shape:[n,t],pads:[q,u,x,y],strides:[h,r]})},941283:(a,b,c,e,f)=>{B.Ea("Gemm",a,{alpha:b,
beta:c,transA:e,transB:f})},941387:a=>{B.Ea("MatMul",a,void 0)},941441:(a,b,c,e)=>{B.Ea("ArgMax",a,{keepDims:!!b,selectLastIndex:!!c,axis:e})},941549:(a,b,c,e)=>{B.Ea("ArgMin",a,{keepDims:!!b,selectLastIndex:!!c,axis:e})},941657:(a,b)=>{B.Ea("Softmax",a,{axis:b})},941720:(a,b)=>{B.Ea("Concat",a,{axis:b})},941780:(a,b,c,e,f)=>{B.Ea("Split",a,{axis:b,numOutputs:c,splitSizes:e?Array.from(z().subarray(e>>>0,f>>>0)):[]})},941920:a=>{B.Ea("Expand",a,void 0)},941974:(a,b)=>{B.Ea("Gather",a,{axis:Number(b)})},
942045:(a,b)=>{B.Ea("GatherElements",a,{axis:Number(b)})},942124:(a,b,c,e,f,g,k,n,t,q,u)=>{B.Ea("Resize",a,{antialias:b,axes:c?Array.from(z().subarray(c>>>0,e>>>0)):[],coordinateTransformMode:T(f),cubicCoeffA:g,excludeOutside:k,extrapolationValue:n,keepAspectRatioPolicy:T(t),mode:T(q),nearestMode:T(u)})},942470:(a,b,c,e,f,g,k)=>{B.Ea("Slice",a,{starts:b?Array.from(z().subarray(b>>>0,c>>>0)):[],ends:e?Array.from(z().subarray(e>>>0,f>>>0)):[],axes:g?Array.from(z().subarray(g>>>0,k>>>0)):[]})},942686:a=>
{B.Ea("Tile",a,void 0)},942738:(a,b,c)=>{B.Ea("LayerNormalization",a,{axis:Number(b),epsilon:Number(c)})},942845:(a,b,c)=>{B.Ea("InstanceNormalization",a,{epsilon:b,format:c?"NHWC":"NCHW"})},942959:(a,b,c)=>{B.Ea("InstanceNormalization",a,{epsilon:b,format:c?"NHWC":"NCHW"})},943073:a=>{B.Ea("Range",a,void 0)},943126:(a,b)=>{B.Ea("Einsum",a,{equation:T(b)})},943207:(a,b,c,e,f)=>{B.Ea("Pad",a,{mode:b,value:c,pads:e?Array.from(z().subarray(e>>>0,f>>>0)):[]})},943334:(a,b,c,e,f,g)=>{B.Ea("BatchNormalization",
a,{epsilon:b,momentum:c,spatial:!!f,trainingMode:!!e,format:g?"NHWC":"NCHW"})},943503:(a,b,c,e,f,g)=>{B.Ea("BatchNormalization",a,{epsilon:b,momentum:c,spatial:!!f,trainingMode:!!e,format:g?"NHWC":"NCHW"})},943672:(a,b,c)=>{B.Ea("CumSum",a,{exclusive:Number(b),reverse:Number(c)})},943769:(a,b,c,e,f,g,k,n,t)=>{B.Ea("Attention",a,{numHeads:b,isUnidirectional:c,maskFilterValue:e,scale:f,doRotary:g,qkvHiddenSizes:k?Array.from(z().subarray(Number(n)>>>0,Number(n)+k>>>0)):[],pastPresentShareBuffer:!!t})},
944041:a=>{B.Ea("BiasAdd",a,void 0)},944096:a=>{B.Ea("BiasSplitGelu",a,void 0)},944157:a=>{B.Ea("FastGelu",a,void 0)},944213:(a,b,c,e,f,g,k,n,t,q,u,x,y)=>{B.Ea("Conv",a,{format:t?"NHWC":"NCHW",auto_pad:b,dilations:[c],group:e,kernel_shape:[f],pads:g?Array.from(z().subarray(g>>>0,k>>>0)):[],strides:[n],w_is_const:()=>!!d()[q>>>0],activation:T(u),activation_params:x?Array.from(da().subarray(x>>>0,y>>>0)):[]})},944583:(a,b,c,e,f,g,k,n,t,q,u,x,y,h,r,w)=>{B.Ea("Conv",a,{format:x?"NHWC":"NCHW",auto_pad:b,
dilations:[c,e],group:f,kernel_shape:[g,k],pads:n?Array.from(z().subarray(n>>>0,t>>>0)):[],strides:[q,u],w_is_const:()=>!!d()[y>>>0],activation:T(h),activation_params:r?Array.from(da().subarray(r>>>0,w>>>0)):[]})},944974:a=>{B.Ea("Gelu",a,void 0)},945026:(a,b,c,e,f,g)=>{B.Ea("MatMulNBits",a,{k:b,n:c,accuracyLevel:e,bits:f,blockSize:g})},945153:(a,b,c,e,f,g)=>{B.Ea("MultiHeadAttention",a,{numHeads:b,isUnidirectional:c,maskFilterValue:e,scale:f,doRotary:g})},945312:(a,b)=>{B.Ea("SkipLayerNormalization",
a,{epsilon:b})},945393:a=>{B.Cb(a)},945427:(a,b)=>B.Eb(a,b,B.bb.Gb,B.bb.errors)};function Ja(a){this.name="ExitStatus";this.message=`Program terminated with exit(${a})`;this.status=a}
var Ka=a=>{a.terminate();a.onmessage=()=>{}},Ma=a=>{0==U.Ya.length&&(La(),U.mb(U.Ya[0]));var b=U.Ya.pop();if(!b)return 6;U.Za.push(b);U.Qa[a.Xa]=b;b.Xa=a.Xa;var c={cmd:"run",start_routine:a.Hb,arg:a.ub,pthread_ptr:a.Xa};F&&b.unref();b.postMessage(c,a.Nb);return 0},V=0,Na="undefined"!=typeof TextDecoder?new TextDecoder("utf8"):void 0,Oa=(a,b,c)=>{b>>>=0;var e=b+c;for(c=b;a[c]&&!(c>=e);)++c;if(16<c-b&&a.buffer&&Na)return Na.decode(a.buffer instanceof SharedArrayBuffer?a.slice(b,c):a.subarray(b,c));
for(e="";b<c;){var f=a[b++];if(f&128){var g=a[b++]&63;if(192==(f&224))e+=String.fromCharCode((f&31)<<6|g);else{var k=a[b++]&63;f=224==(f&240)?(f&15)<<12|g<<6|k:(f&7)<<18|g<<12|k<<6|a[b++]&63;65536>f?e+=String.fromCharCode(f):(f-=65536,e+=String.fromCharCode(55296|f>>10,56320|f&1023))}}else e+=String.fromCharCode(f)}return e},T=(a,b)=>(a>>>=0)?Oa(v(),a,b):"",Ra=a=>{var b=Pa();a=a();Qa(b);return a};
function W(a,b){var c=arguments.length-2,e=arguments;return Ra(()=>{for(var f=Sa(8*c),g=f>>>3,k=0;k<c;k++){var n=e[2+k];fa()[g+k>>>0]=n}return Ta(a,c,f,b)})}function Ua(a){if(G)return W(0,1,a);O=a;0<V||(U.Ib(),B.onExit?.(a),M=!0);na(a,new Ja(a))}var Wa=a=>{O=a;if(G)throw Va(a),"unwind";Ua(a)},Xa=a=>{a instanceof Ja||"unwind"==a||na(1,a)};function Ya(){for(var a=B.numThreads;a--;)La();ya.unshift(()=>{P++;Za(()=>Ca())})}
function La(){var a=pa("ort-wasm-simd-threaded.worker.js");a=new Worker(a);U.Ya.push(a)}function Za(a){G?a():Promise.all(U.Ya.map(U.mb)).then(a)}
var U={Ya:[],Za:[],qb:[],Qa:{},hb(){G?(U.receiveObjectTransfer=U.Fb,U.threadInitTLS=U.pb,U.setExitStatus=U.ob):Ya()},ob:a=>O=a,Qb:["$terminateWorker"],Ib:()=>{for(var a of U.Za)Ka(a);for(a of U.Ya)Ka(a);U.Ya=[];U.Za=[];U.Qa=[]},nb:a=>{var b=a.Xa;delete U.Qa[b];U.Ya.push(a);U.Za.splice(U.Za.indexOf(a),1);a.Xa=0;$a(b)},Fb(){},pb(){U.qb.forEach(a=>a())},mb:a=>new Promise(b=>{a.onmessage=g=>{g=g.data;var k=g.cmd;if(g.targetThread&&g.targetThread!=ab()){var n=U.Qa[g.targetThread];n?n.postMessage(g,g.transferList):
K(`Internal error! Worker sent a message "${k}" to target pthread ${g.targetThread}, but that thread no longer exists!`)}else if("checkMailbox"===k)bb();else if("spawnThread"===k)Ma(g);else if("cleanupThread"===k)U.nb(U.Qa[g.thread]);else if("killThread"===k)g=g.thread,k=U.Qa[g],delete U.Qa[g],Ka(k),$a(g),U.Za.splice(U.Za.indexOf(k),1),k.Xa=0;else if("cancelThread"===k)U.Qa[g.thread].postMessage({cmd:"cancel"});else if("loaded"===k)a.loaded=!0,F&&!a.Xa&&a.unref(),b(a);else if("alert"===k)alert(`Thread ${g.threadId}: ${g.text}`);
else if("setimmediate"===g.target)a.postMessage(g);else if("callHandler"===k)B[g.handler](...g.args);else k&&K(`worker sent an unknown command ${k}`)};a.onerror=g=>{K(`${"worker sent an error!"} ${g.filename}:${g.lineno}: ${g.message}`);throw g;};F&&(a.on("message",g=>a.onmessage({data:g})),a.on("error",g=>a.onerror(g)));var c=[],e=["onExit"],f;for(f of e)B.hasOwnProperty(f)&&c.push(f);a.postMessage({cmd:"load",handlers:c,urlOrBlob:B.mainScriptUrlOrBlob||_scriptDir,wasmMemory:l,wasmModule:wa})})};
B.PThread=U;var cb=a=>{for(;0<a.length;)a.shift()(B)};B.establishStackSpace=()=>{var a=ab(),b=A()[a+52>>>2>>>0];a=A()[a+56>>>2>>>0];db(b,b-a);Qa(b)};function Va(a){if(G)return W(1,0,a);Wa(a)}B.invokeEntryPoint=(a,b)=>{a=eb.apply(null,[a,b]);0<V?U.ob(a):fb(a)};function gb(a){this.gb=a-24;this.tb=function(b){A()[this.gb+4>>>2>>>0]=b};this.sb=function(b){A()[this.gb+8>>>2>>>0]=b};this.hb=function(b,c){this.rb();this.tb(b);this.sb(c)};this.rb=function(){A()[this.gb+16>>>2>>>0]=0}}var hb=0,ib=0;
function jb(a,b,c,e){return G?W(2,1,a,b,c,e):kb(a,b,c,e)}function kb(a,b,c,e){a>>>=0;b>>>=0;c>>>=0;e>>>=0;if("undefined"==typeof SharedArrayBuffer)return K("Current environment does not support SharedArrayBuffer, pthreads are not available!"),6;var f=[];if(G&&0===f.length)return jb(a,b,c,e);a={Hb:c,Xa:a,ub:e,Nb:f};return G?(a.Pb="spawnThread",postMessage(a,f),0):Ma(a)}function lb(a,b,c){return G?W(3,1,a,b,c):0}function mb(a,b){if(G)return W(4,1,a,b)}
var nb=a=>{for(var b=0,c=0;c<a.length;++c){var e=a.charCodeAt(c);127>=e?b++:2047>=e?b+=2:55296<=e&&57343>=e?(b+=4,++c):b+=3}return b},ob=(a,b,c,e)=>{c>>>=0;if(!(0<e))return 0;var f=c;e=c+e-1;for(var g=0;g<a.length;++g){var k=a.charCodeAt(g);if(55296<=k&&57343>=k){var n=a.charCodeAt(++g);k=65536+((k&1023)<<10)|n&1023}if(127>=k){if(c>=e)break;b[c++>>>0]=k}else{if(2047>=k){if(c+1>=e)break;b[c++>>>0]=192|k>>6}else{if(65535>=k){if(c+2>=e)break;b[c++>>>0]=224|k>>12}else{if(c+3>=e)break;b[c++>>>0]=240|k>>
18;b[c++>>>0]=128|k>>12&63}b[c++>>>0]=128|k>>6&63}b[c++>>>0]=128|k&63}}b[c>>>0]=0;return c-f},pb=(a,b,c)=>ob(a,v(),b,c);function qb(a,b){if(G)return W(5,1,a,b)}function rb(a,b,c){if(G)return W(6,1,a,b,c)}function sb(a,b,c){return G?W(7,1,a,b,c):0}function tb(a,b){if(G)return W(8,1,a,b)}function ub(a,b,c){if(G)return W(9,1,a,b,c)}function vb(a,b,c,e){if(G)return W(10,1,a,b,c,e)}function wb(a,b,c,e){if(G)return W(11,1,a,b,c,e)}function xb(a,b,c,e){if(G)return W(12,1,a,b,c,e)}
function yb(a){if(G)return W(13,1,a)}function zb(a,b){if(G)return W(14,1,a,b)}function Ab(a,b,c){if(G)return W(15,1,a,b,c)}var Bb=()=>{if(!(0<V))try{G?fb(O):Wa(O)}catch(a){Xa(a)}};function Cb(a){a>>>=0;"function"===typeof Atomics.Ob&&(Atomics.Ob(z(),a>>>2,a).value.then(bb),a+=128,Atomics.store(z(),a>>>2,1))}B.__emscripten_thread_mailbox_await=Cb;var bb=()=>{var a=ab();if(a&&(Cb(a),!M))try{Db(),Bb()}catch(b){Xa(b)}};B.checkMailbox=bb;
var Eb=[],X=a=>0===a%4&&(0!==a%100||0===a%400),Fb=[0,31,60,91,121,152,182,213,244,274,305,335],Gb=[0,31,59,90,120,151,181,212,243,273,304,334];function Hb(a,b,c,e,f,g,k,n){return G?W(16,1,a,b,c,e,f,g,k,n):-52}function Ib(a,b,c,e,f,g,k){if(G)return W(17,1,a,b,c,e,f,g,k)}
var Kb=a=>{var b=nb(a)+1,c=Jb(b);c&&pb(a,c,b);return c},Lb=[],Mb=(a,b)=>{Lb.length=0;for(var c;c=v()[a++>>>0];){var e=105!=c;e&=112!=c;b+=e&&b%8?4:0;Lb.push(112==c?A()[b>>>2>>>0]:105==c?z()[b>>>2>>>0]:fa()[b>>>3>>>0]);b+=e?8:4}return Lb},Nb={},Pb=()=>{if(!Ob){var a={USER:"web_user",LOGNAME:"web_user",PATH:"/",PWD:"/",HOME:"/home/web_user",LANG:("object"==typeof navigator&&navigator.languages&&navigator.languages[0]||"C").replace("-","_")+".UTF-8",_:ma||"./this.program"},b;for(b in Nb)void 0===Nb[b]?
delete a[b]:a[b]=Nb[b];var c=[];for(b in a)c.push(`${b}=${a[b]}`);Ob=c}return Ob},Ob;function Qb(a,b){if(G)return W(18,1,a,b);a>>>=0;b>>>=0;var c=0;Pb().forEach((e,f)=>{var g=b+c;f=A()[a+4*f>>>2>>>0]=g;for(g=0;g<e.length;++g)d()[f++>>>0>>>0]=e.charCodeAt(g);d()[f>>>0>>>0]=0;c+=e.length+1});return 0}function Rb(a,b){if(G)return W(19,1,a,b);a>>>=0;b>>>=0;var c=Pb();A()[a>>>2>>>0]=c.length;var e=0;c.forEach(f=>e+=f.length+1);A()[b>>>2>>>0]=e;return 0}function Sb(a){return G?W(20,1,a):52}
function Ub(a,b,c,e){return G?W(21,1,a,b,c,e):52}function Vb(a,b,c,e,f){return G?W(22,1,a,b,c,e,f):70}var Wb=[null,[],[]];function Xb(a,b,c,e){if(G)return W(23,1,a,b,c,e);b>>>=0;c>>>=0;e>>>=0;for(var f=0,g=0;g<c;g++){var k=A()[b>>>2>>>0],n=A()[b+4>>>2>>>0];b+=8;for(var t=0;t<n;t++){var q=v()[k+t>>>0],u=Wb[a];0===q||10===q?((1===a?va:K)(Oa(u,0)),u.length=0):u.push(q)}f+=n}A()[e>>>2>>>0]=f;return 0}var Yb=[31,29,31,30,31,30,31,31,30,31,30,31],Zb=[31,28,31,30,31,30,31,31,30,31,30,31];
function $b(a){var b=Array(nb(a)+1);ob(a,b,0,b.length);return b}var ac=(a,b)=>{d().set(a,b>>>0)};
function bc(a,b,c,e){function f(h,r,w){for(h="number"==typeof h?h.toString():h||"";h.length<r;)h=w[0]+h;return h}function g(h,r){return f(h,r,"0")}function k(h,r){function w(Tb){return 0>Tb?-1:0<Tb?1:0}var N;0===(N=w(h.getFullYear()-r.getFullYear()))&&0===(N=w(h.getMonth()-r.getMonth()))&&(N=w(h.getDate()-r.getDate()));return N}function n(h){switch(h.getDay()){case 0:return new Date(h.getFullYear()-1,11,29);case 1:return h;case 2:return new Date(h.getFullYear(),0,3);case 3:return new Date(h.getFullYear(),
0,2);case 4:return new Date(h.getFullYear(),0,1);case 5:return new Date(h.getFullYear()-1,11,31);case 6:return new Date(h.getFullYear()-1,11,30)}}function t(h){var r=h.$a;for(h=new Date((new Date(h.ab+1900,0,1)).getTime());0<r;){var w=h.getMonth(),N=(X(h.getFullYear())?Yb:Zb)[w];if(r>N-h.getDate())r-=N-h.getDate()+1,h.setDate(1),11>w?h.setMonth(w+1):(h.setMonth(0),h.setFullYear(h.getFullYear()+1));else{h.setDate(h.getDate()+r);break}}w=new Date(h.getFullYear()+1,0,4);r=n(new Date(h.getFullYear(),
0,4));w=n(w);return 0>=k(r,h)?0>=k(w,h)?h.getFullYear()+1:h.getFullYear():h.getFullYear()-1}a>>>=0;b>>>=0;c>>>=0;e>>>=0;var q=A()[e+40>>>2>>>0];e={Lb:z()[e>>>2>>>0],Kb:z()[e+4>>>2>>>0],eb:z()[e+8>>>2>>>0],jb:z()[e+12>>>2>>>0],fb:z()[e+16>>>2>>>0],ab:z()[e+20>>>2>>>0],Wa:z()[e+24>>>2>>>0],$a:z()[e+28>>>2>>>0],Rb:z()[e+32>>>2>>>0],Jb:z()[e+36>>>2>>>0],Mb:q?T(q):""};c=T(c);q={"%c":"%a %b %d %H:%M:%S %Y","%D":"%m/%d/%y","%F":"%Y-%m-%d","%h":"%b","%r":"%I:%M:%S %p","%R":"%H:%M","%T":"%H:%M:%S","%x":"%m/%d/%y",
"%X":"%H:%M:%S","%Ec":"%c","%EC":"%C","%Ex":"%m/%d/%y","%EX":"%H:%M:%S","%Ey":"%y","%EY":"%Y","%Od":"%d","%Oe":"%e","%OH":"%H","%OI":"%I","%Om":"%m","%OM":"%M","%OS":"%S","%Ou":"%u","%OU":"%U","%OV":"%V","%Ow":"%w","%OW":"%W","%Oy":"%y"};for(var u in q)c=c.replace(new RegExp(u,"g"),q[u]);var x="Sunday Monday Tuesday Wednesday Thursday Friday Saturday".split(" "),y="January February March April May June July August September October November December".split(" ");q={"%a":h=>x[h.Wa].substring(0,3),"%A":h=>
x[h.Wa],"%b":h=>y[h.fb].substring(0,3),"%B":h=>y[h.fb],"%C":h=>g((h.ab+1900)/100|0,2),"%d":h=>g(h.jb,2),"%e":h=>f(h.jb,2," "),"%g":h=>t(h).toString().substring(2),"%G":h=>t(h),"%H":h=>g(h.eb,2),"%I":h=>{h=h.eb;0==h?h=12:12<h&&(h-=12);return g(h,2)},"%j":h=>{for(var r=0,w=0;w<=h.fb-1;r+=(X(h.ab+1900)?Yb:Zb)[w++]);return g(h.jb+r,3)},"%m":h=>g(h.fb+1,2),"%M":h=>g(h.Kb,2),"%n":()=>"\n","%p":h=>0<=h.eb&&12>h.eb?"AM":"PM","%S":h=>g(h.Lb,2),"%t":()=>"\t","%u":h=>h.Wa||7,"%U":h=>g(Math.floor((h.$a+7-h.Wa)/
7),2),"%V":h=>{var r=Math.floor((h.$a+7-(h.Wa+6)%7)/7);2>=(h.Wa+371-h.$a-2)%7&&r++;if(r)53==r&&(w=(h.Wa+371-h.$a)%7,4==w||3==w&&X(h.ab)||(r=1));else{r=52;var w=(h.Wa+7-h.$a-1)%7;(4==w||5==w&&X(h.ab%400-1))&&r++}return g(r,2)},"%w":h=>h.Wa,"%W":h=>g(Math.floor((h.$a+7-(h.Wa+6)%7)/7),2),"%y":h=>(h.ab+1900).toString().substring(2),"%Y":h=>h.ab+1900,"%z":h=>{h=h.Jb;var r=0<=h;h=Math.abs(h)/60;return(r?"+":"-")+String("0000"+(h/60*100+h%60)).slice(-4)},"%Z":h=>h.Mb,"%%":()=>"%"};c=c.replace(/%%/g,"\x00\x00");
for(u in q)c.includes(u)&&(c=c.replace(new RegExp(u,"g"),q[u](e)));c=c.replace(/\0\0/g,"%");u=$b(c);if(u.length>b)return 0;ac(u,a);return u.length-1}var cc=a=>{try{a()}catch(b){L(b)}};function dc(){var a=Y,b={};for(let [c,e]of Object.entries(a))b[c]="function"==typeof e?function(){ec.push(c);try{return e.apply(null,arguments)}finally{M||(ec.pop(),D&&1===Z&&0===ec.length&&(Z=0,V+=1,cc(fc),"undefined"!=typeof Fibers&&Fibers.Sb()))}}:e;return b}var Z=0,D=null,gc=0,ec=[],hc={},ic={},jc=0,kc=null,lc=[];
function ja(){return new Promise((a,b)=>{kc={resolve:a,reject:b}})}function mc(){var a=Jb(65548),b=a+12;A()[a>>>2>>>0]=b;A()[a+4>>>2>>>0]=b+65536;b=ec[0];var c=hc[b];void 0===c&&(c=jc++,hc[b]=c,ic[c]=b);b=c;z()[a+8>>>2>>>0]=b;return a}function nc(){var a=z()[D+8>>>2>>>0];a=Y[ic[a]];--V;return a()}
function oc(a){if(!M){if(0===Z){var b=!1,c=!1;a((e=0)=>{if(!M&&(gc=e,b=!0,c)){Z=2;cc(()=>pc(D));"undefined"!=typeof Browser&&Browser.ib.wb&&Browser.ib.resume();e=!1;try{var f=nc()}catch(n){f=n,e=!0}var g=!1;if(!D){var k=kc;k&&(kc=null,(e?k.reject:k.resolve)(f),g=!0)}if(e&&!g)throw f;}});c=!0;b||(Z=1,D=mc(),"undefined"!=typeof Browser&&Browser.ib.wb&&Browser.ib.pause(),cc(()=>qc(D)))}else 2===Z?(Z=0,cc(rc),sc(D),D=null,lc.forEach(e=>{if(!M)try{e(),Bb()}catch(f){Xa(f)}})):L(`invalid state: ${Z}`);return gc}}
function tc(a){return oc(b=>{a().then(b)})}U.hb();
var uc=[Ua,Va,jb,lb,mb,qb,rb,sb,tb,ub,vb,wb,xb,yb,zb,Ab,Hb,Ib,Qb,Rb,Sb,Ub,Vb,Xb],yc={r:function(a,b,c){return tc(async()=>{await B.Ab(a,b,c)})},b:function(a,b,c){a>>>=0;(new gb(a)).hb(b>>>0,c>>>0);hb=a;ib++;throw hb;},N:function(a){vc(a>>>0,!E,1,!oa,131072,!1);U.pb()},l:function(a){a>>>=0;G?postMessage({cmd:"cleanupThread",thread:a}):U.nb(U.Qa[a])},J:kb,i:lb,T:mb,F:qb,H:rb,U:sb,R:tb,L:ub,Q:vb,p:wb,G:xb,D:yb,S:zb,E:Ab,q:()=>1,B:function(a,b){a>>>=0;a==b>>>0?setTimeout(()=>bb()):G?postMessage({targetThread:a,
cmd:"checkMailbox"}):(a=U.Qa[a])&&a.postMessage({cmd:"checkMailbox"})},K:function(a,b,c,e){b>>>=0;Eb.length=c;e=e>>>0>>>3;for(var f=0;f<c;f++)Eb[f]=fa()[e+f>>>0];a=0>a?Ia[-a-1]:uc[a];U.vb=b;b=a.apply(null,Eb);U.vb=0;return b},M:Cb,W:function(a){F&&U.Qa[a>>>0].ref()},u:function(a,b,c){a=b+2097152>>>0<4194305-!!a?(a>>>0)+4294967296*b:NaN;c>>>=0;a=new Date(1E3*a);z()[c>>>2>>>0]=a.getUTCSeconds();z()[c+4>>>2>>>0]=a.getUTCMinutes();z()[c+8>>>2>>>0]=a.getUTCHours();z()[c+12>>>2>>>0]=a.getUTCDate();z()[c+
16>>>2>>>0]=a.getUTCMonth();z()[c+20>>>2>>>0]=a.getUTCFullYear()-1900;z()[c+24>>>2>>>0]=a.getUTCDay();a=(a.getTime()-Date.UTC(a.getUTCFullYear(),0,1,0,0,0,0))/864E5|0;z()[c+28>>>2>>>0]=a},v:function(a,b,c){a=b+2097152>>>0<4194305-!!a?(a>>>0)+4294967296*b:NaN;c>>>=0;a=new Date(1E3*a);z()[c>>>2>>>0]=a.getSeconds();z()[c+4>>>2>>>0]=a.getMinutes();z()[c+8>>>2>>>0]=a.getHours();z()[c+12>>>2>>>0]=a.getDate();z()[c+16>>>2>>>0]=a.getMonth();z()[c+20>>>2>>>0]=a.getFullYear()-1900;z()[c+24>>>2>>>0]=a.getDay();
b=(X(a.getFullYear())?Fb:Gb)[a.getMonth()]+a.getDate()-1|0;z()[c+28>>>2>>>0]=b;z()[c+36>>>2>>>0]=-(60*a.getTimezoneOffset());b=(new Date(a.getFullYear(),6,1)).getTimezoneOffset();var e=(new Date(a.getFullYear(),0,1)).getTimezoneOffset();a=(b!=e&&a.getTimezoneOffset()==Math.min(e,b))|0;z()[c+32>>>2>>>0]=a},w:function(a){a>>>=0;var b=new Date(z()[a+20>>>2>>>0]+1900,z()[a+16>>>2>>>0],z()[a+12>>>2>>>0],z()[a+8>>>2>>>0],z()[a+4>>>2>>>0],z()[a>>>2>>>0],0),c=z()[a+32>>>2>>>0],e=b.getTimezoneOffset(),f=(new Date(b.getFullYear(),
6,1)).getTimezoneOffset(),g=(new Date(b.getFullYear(),0,1)).getTimezoneOffset(),k=Math.min(g,f);0>c?z()[a+32>>>2>>>0]=Number(f!=g&&k==e):0<c!=(k==e)&&(f=Math.max(g,f),b.setTime(b.getTime()+6E4*((0<c?k:f)-e)));z()[a+24>>>2>>>0]=b.getDay();c=(X(b.getFullYear())?Fb:Gb)[b.getMonth()]+b.getDate()-1|0;z()[a+28>>>2>>>0]=c;z()[a>>>2>>>0]=b.getSeconds();z()[a+4>>>2>>>0]=b.getMinutes();z()[a+8>>>2>>>0]=b.getHours();z()[a+12>>>2>>>0]=b.getDate();z()[a+16>>>2>>>0]=b.getMonth();z()[a+20>>>2>>>0]=b.getYear();a=
b.getTime();isNaN(a)?(z()[wc()>>>2>>>0]=61,a=-1):a/=1E3;return xc((S=a,1<=+Math.abs(S)?0<S?+Math.floor(S/4294967296)>>>0:~~+Math.ceil((S-+(~~S>>>0))/4294967296)>>>0:0)),a>>>0},s:Hb,t:Ib,A:function(a,b,c){function e(q){return(q=q.toTimeString().match(/\(([A-Za-z ]+)\)$/))?q[1]:"GMT"}a>>>=0;b>>>=0;c>>>=0;var f=(new Date).getFullYear(),g=new Date(f,0,1),k=new Date(f,6,1);f=g.getTimezoneOffset();var n=k.getTimezoneOffset(),t=Math.max(f,n);A()[a>>>2>>>0]=60*t;z()[b>>>2>>>0]=Number(f!=n);a=e(g);b=e(k);
a=Kb(a);b=Kb(b);n<f?(A()[c>>>2>>>0]=a,A()[c+4>>>2>>>0]=b):(A()[c>>>2>>>0]=b,A()[c+4>>>2>>>0]=a)},d:()=>{L("")},c:function(a,b,c){a>>>=0;b=Mb(b>>>0,c>>>0);return Ia[a].apply(null,b)},k:function(a,b,c){a>>>=0;b=Mb(b>>>0,c>>>0);return Ia[a].apply(null,b)},m:()=>{},j:()=>Date.now(),V:()=>{V+=1;throw"unwind";},C:function(){return 4294901760},f:()=>performance.timeOrigin+performance.now(),g:()=>F?require("os").cpus().length:navigator.hardwareConcurrency,y:function(a){a>>>=0;var b=v().length;if(a<=b||4294901760<
a)return!1;for(var c=1;4>=c;c*=2){var e=b*(1+.2/c);e=Math.min(e,a+100663296);var f=Math;e=Math.max(a,e);a:{f=(f.min.call(f,4294901760,e+(65536-e%65536)%65536)-l.buffer.byteLength+65535)/65536;try{l.grow(f);p();var g=1;break a}catch(k){}g=void 0}if(g)return!0}return!1},O:Qb,P:Rb,I:Wa,h:Sb,o:Ub,x:Vb,n:Xb,a:l||B.wasmMemory,z:bc,e:function(a,b,c,e){return bc(a>>>0,b>>>0,c>>>0,e>>>0)}},Y=function(){function a(c,e){Y=c.exports;Y=dc();Y=zc();U.qb.push(Y.Da);za.unshift(Y.X);wa=e;Ca();return Y}var b={a:yc};
P++;if(B.instantiateWasm)try{return B.instantiateWasm(b,a)}catch(c){K(`Module.instantiateWasm callback failed with error: ${c}`),C(c)}Ha(b,function(c){a(c.instance,c.module)}).catch(C);return{}}();B._OrtInit=(a,b)=>(B._OrtInit=Y.Y)(a,b);B._OrtGetLastError=(a,b)=>(B._OrtGetLastError=Y.Z)(a,b);B._OrtCreateSessionOptions=(a,b,c,e,f,g,k,n,t,q)=>(B._OrtCreateSessionOptions=Y._)(a,b,c,e,f,g,k,n,t,q);B._OrtAppendExecutionProvider=(a,b)=>(B._OrtAppendExecutionProvider=Y.$)(a,b);
B._OrtAddFreeDimensionOverride=(a,b,c)=>(B._OrtAddFreeDimensionOverride=Y.aa)(a,b,c);B._OrtAddSessionConfigEntry=(a,b,c)=>(B._OrtAddSessionConfigEntry=Y.ba)(a,b,c);B._OrtReleaseSessionOptions=a=>(B._OrtReleaseSessionOptions=Y.ca)(a);B._OrtCreateSession=(a,b,c)=>(B._OrtCreateSession=Y.da)(a,b,c);B._OrtReleaseSession=a=>(B._OrtReleaseSession=Y.ea)(a);B._OrtGetInputOutputCount=(a,b,c)=>(B._OrtGetInputOutputCount=Y.fa)(a,b,c);B._OrtGetInputName=(a,b)=>(B._OrtGetInputName=Y.ga)(a,b);
B._OrtGetOutputName=(a,b)=>(B._OrtGetOutputName=Y.ha)(a,b);B._OrtFree=a=>(B._OrtFree=Y.ia)(a);B._OrtCreateTensor=(a,b,c,e,f,g)=>(B._OrtCreateTensor=Y.ja)(a,b,c,e,f,g);B._OrtGetTensorData=(a,b,c,e,f)=>(B._OrtGetTensorData=Y.ka)(a,b,c,e,f);B._OrtReleaseTensor=a=>(B._OrtReleaseTensor=Y.la)(a);B._OrtCreateRunOptions=(a,b,c,e)=>(B._OrtCreateRunOptions=Y.ma)(a,b,c,e);B._OrtAddRunConfigEntry=(a,b,c)=>(B._OrtAddRunConfigEntry=Y.na)(a,b,c);B._OrtReleaseRunOptions=a=>(B._OrtReleaseRunOptions=Y.oa)(a);
B._OrtCreateBinding=a=>(B._OrtCreateBinding=Y.pa)(a);B._OrtBindInput=(a,b,c)=>(B._OrtBindInput=Y.qa)(a,b,c);B._OrtBindOutput=(a,b,c,e)=>(B._OrtBindOutput=Y.ra)(a,b,c,e);B._OrtClearBoundOutputs=a=>(B._OrtClearBoundOutputs=Y.sa)(a);B._OrtReleaseBinding=a=>(B._OrtReleaseBinding=Y.ta)(a);B._OrtRunWithBinding=(a,b,c,e,f)=>(B._OrtRunWithBinding=Y.ua)(a,b,c,e,f);B._OrtRun=(a,b,c,e,f,g,k,n)=>(B._OrtRun=Y.va)(a,b,c,e,f,g,k,n);B._OrtEndProfiling=a=>(B._OrtEndProfiling=Y.wa)(a);
B._JsepOutput=(a,b,c)=>(B._JsepOutput=Y.xa)(a,b,c);B._JsepGetNodeName=a=>(B._JsepGetNodeName=Y.ya)(a);var wc=()=>(wc=Y.za)(),ab=B._pthread_self=()=>(ab=B._pthread_self=Y.Aa)(),Jb=B._malloc=a=>(Jb=B._malloc=Y.Ba)(a),sc=B._free=a=>(sc=B._free=Y.Ca)(a);B.__emscripten_tls_init=()=>(B.__emscripten_tls_init=Y.Da)();var vc=B.__emscripten_thread_init=(a,b,c,e,f,g)=>(vc=B.__emscripten_thread_init=Y.Fa)(a,b,c,e,f,g);B.__emscripten_thread_crashed=()=>(B.__emscripten_thread_crashed=Y.Ga)();
var Ta=(a,b,c,e)=>(Ta=Y.Ha)(a,b,c,e),$a=a=>($a=Y.Ia)(a),fb=B.__emscripten_thread_exit=a=>(fb=B.__emscripten_thread_exit=Y.Ja)(a),Db=()=>(Db=Y.Ka)(),xc=a=>(xc=Y.La)(a),db=(a,b)=>(db=Y.Ma)(a,b),Pa=()=>(Pa=Y.Na)(),Qa=a=>(Qa=Y.Oa)(a),Sa=a=>(Sa=Y.Pa)(a),eb=B.dynCall_ii=(a,b)=>(eb=B.dynCall_ii=Y.Ra)(a,b),qc=a=>(qc=Y.Sa)(a),fc=()=>(fc=Y.Ta)(),pc=a=>(pc=Y.Ua)(a),rc=()=>(rc=Y.Va)();B.___start_em_js=945539;B.___stop_em_js=945700;
function zc(){var a=Y;a=Object.assign({},a);var b=e=>()=>e()>>>0,c=e=>f=>e(f)>>>0;a.za=b(a.za);a.Aa=b(a.Aa);a.Ba=c(a.Ba);a.emscripten_main_runtime_thread_id=b(a.emscripten_main_runtime_thread_id);a.Na=b(a.Na);a.Pa=c(a.Pa);return a}B.wasmMemory=l;B.stackAlloc=Sa;B.stackSave=Pa;B.stackRestore=Qa;B.keepRuntimeAlive=()=>0<V;B.UTF8ToString=T;B.stringToUTF8=pb;B.lengthBytesUTF8=nb;B.ExitStatus=Ja;B.PThread=U;var Ac;Q=function Bc(){Ac||Cc();Ac||(Q=Bc)};
function Cc(){if(!(0<P))if(G)ia(B),G||cb(za),startWorker(B);else{if(B.preRun)for("function"==typeof B.preRun&&(B.preRun=[B.preRun]);B.preRun.length;)ya.unshift(B.preRun.shift());cb(ya);0<P||Ac||(Ac=!0,B.calledRun=!0,M||(G||cb(za),ia(B),G||cb(Aa)))}}Cc();


  return moduleArg.ready
}
);
})();
;
if (typeof exports === 'object' && typeof module === 'object')
  module.exports = ortWasmThreaded;
else if (typeof define === 'function' && define['amd'])
  define([], () => ortWasmThreaded);
